import React from 'react';
import logo from './logo.svg';
import './App.css';


function App() {

  return (
    <div className="App">
      <div class="container">
        <img src={logo} className="App-logo" alt="logo" />
      </div>
    </div>
  );
}

export default App;
